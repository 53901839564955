<template>
  <div>
    <div class="modal-body">
      <div class="form-row">
        <div
          class="form-group col-md-12 required"
          :class="{ 'is-invalid': errors.has('name') }"
        >
          <label for="name_field" class="control-label text-capitalize"
            >Name:</label
          >
          <input
            id="name_field"
            v-model="name"
            v-validate="{ required: true }"
            type="text"
            name="name"
            class="form-control"
          />
          <span v-show="errors.has('name')" class="invalid-feedback">{{
            errors.first("name")
          }}</span>
        </div>
      </div>
      <div class="form-row">
        <div
          class="form-group col-md-12 required"
          :class="{ 'is-invalid': errors.has('key') }"
        >
          <label for="key_field" class="control-label text-capitalize"
            >Key:</label
          >
          <input
            id="key_field"
            v-model="key"
            v-validate="{ required: true }"
            type="text"
            name="key"
            class="form-control"
          />
          <span v-show="errors.has('key')" class="invalid-feedback">{{
            errors.first("key")
          }}</span>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <ButtonLoading
        :loading="loading"
        :title="'Save'"
        :class="'btn btn-secondary float-right'"
        v-on:click.native="submitForm"
      />
    </div>
  </div>
</template>

<script>
import ButtonLoading from "./ButtonLoading.vue";

export default {
  name: "StatusForm",
  components: {
    ButtonLoading,
  },
  data() {
    return {
      loadingDelete: false,
      loading: false,
      name: "",
      key: "",
    };
  },
  methods: {
    async submitForm() {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = false;
          this.$emit("add-status", {
            label: this.name,
            key: this.key,
          });
          this.closeModal();
        } else {
          this.loading = false;
        }
      });
    },
    closeModal() {
      this.$emit("close");
    },
  },
  watch: {
    name: function (val) {
      this.key = val.replaceAll(" ", "-").toLowerCase();
    },
  },
};
</script>
