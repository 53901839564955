var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.layout,{tag:"component"},[_c('prompt',{attrs:{"active":_vm.isModalVisible},on:{"close":function($event){_vm.isModalVisible = false}}},[_c('h3',{attrs:{"slot":"header"},slot:"header"},[_vm._v(" Add new status ")]),_c('div',{attrs:{"slot":"content"},slot:"content"},[(_vm.hasPermission('create-status'))?_c('status-form',{on:{"close":function($event){_vm.isModalVisible = false},"add-status":_vm.addStatus}}):_vm._e()],1)]),_c('prompt',{attrs:{"active":_vm.isEditStatusVisible},on:{"close":function($event){_vm.isEditStatusVisible = false}}},[_c('h3',{attrs:{"slot":"header"},slot:"header"},[_vm._v(" Edit "+_vm._s(_vm.selectedStatus.label)+" ")]),_c('div',{attrs:{"slot":"content"},slot:"content"},[(_vm.hasPermission('create-status') && _vm.isEditStatusVisible)?_c('status-edit-form',{attrs:{"status":_vm.selectedStatus},on:{"close":function($event){_vm.isEditStatusVisible = false},"update-status":_vm.updateStatus}}):_vm._e()],1)]),_c('div',{staticClass:"row fadeInUp",attrs:{"role":"main"}},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-3"},[_c('ConfigurationNav')],1),_c('div',{staticClass:"col-12 col-md-9"},[_c('div',{staticClass:"card border-0 shadow-sm"},[_c('div',{staticClass:"card-header"},[_c('h4',[_vm._v(" Status "),(_vm.hasPermission('create-status'))?_c('button',{staticClass:"btn btn-sm float-right btn-outline-secondary m-1",on:{"click":_vm.showStatusModal}},[_vm._v(" Add status ")]):_vm._e()])]),_c('div',[(Object.keys(_vm.statusList).length !== 0)?_c('div',{staticClass:"card-body"},[_c('data-table',{attrs:{"headers":_vm.headers(),"data":_vm.statusList,"sort-url":'/configuration/status',"method":'get'},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.label)+" ")]}},{key:"slug",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.key)+" ")]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatLocaleDateString")(item.created_at))+" ")]}},{key:"updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatLocaleDateString")(item.updated_at))+" ")]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('button',{staticClass:"btn btn-sm btn-outline-secondary m-1",on:{"click":function($event){return _vm.showEditModal(item)}}},[_vm._v(" Edit ")]),_c('DeleteButton',{staticClass:"d-inline-block",attrs:{"uri":'',"name":item.name,"isSmall":true},on:{"confirm":function($event){return _vm.deleteContent(item)}}})],1)]}}],null,false,922466134)})],1):_c('div',{staticClass:"card-body"},[_c('p',{staticClass:"text-muted"},[_vm._v(" This is a list of your statuses. ")]),_c('div',{staticClass:"p-4 rounded border-zip text-center"},[_c('p',{staticClass:"mb-0"},[_vm._v(" No statuses available! ")])])])])])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }