<template>
    <div>
      <div class="modal-body">
        <div class="form-row">
          <div
            class="form-group col-md-12 required"
            :class="{ 'is-invalid': errors.has('name') }"
          >
            <label for="name_field" class="control-label text-capitalize"
              >Name:</label
            >
            <input
              id="name_field"
              v-model="status.label"
              v-validate="{ required: true }"
              type="text"
              name="name"
              class="form-control"
            />
            <span v-show="errors.has('name')" class="invalid-feedback">{{
              errors.first("name")
            }}</span>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <ButtonLoading
          :loading="loading"
          :title="'Save'"
          :class="'btn btn-secondary float-right'"
          v-on:click.native="submitForm"
        />
      </div>
    </div>
  </template>
  
  <script>
  import ButtonLoading from "./ButtonLoading.vue";
  
  export default {
    name: "StatusForm",
    components: {
      ButtonLoading,
    },
    props: {
        status: {
            type: Object,
            default: () => {
                return {
                    key: "",
                    label: "",
                };
            },
        }
    },
    data() {
      return {
        loading: false,
        name: "",
      };
    },
    methods: {
      async submitForm() {
        this.loading = true;
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.loading = false;
            this.$emit("update-status", this.status);
            this.closeModal();
          } else {
            this.loading = false;
          }
        });
      },
      closeModal() {
        this.$emit("close");
      },
    },
  };
  </script>
  