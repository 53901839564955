


































import { Component, Prop, Vue } from "vue-property-decorator";
import jsonAPI from "@/api";
import DeleteCircle from "@/components/Svg/DeleteCircle.vue";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";
import Prompt from "@/components/UI/Prompt.vue";

@Component({
  name: "DeleteButton",
  components: {
    DeleteCircle,
    ButtonLoading,
    Prompt,
  },
})
export default class DeleteButton extends Vue {
  @Prop() name!: string;
  @Prop() uri!: string;
  @Prop() isSmall: boolean | undefined;

  isPromptVisible = false;
  loading = false;

  get classes() {
    return {
      "btn-outline-danger": true,
      "btn-sm": this.isSmall ?? false,
    };
  }
  showPrompt() {
    window.scrollTo(0, 0);
    this.isPromptVisible = true;
  }
  close() {
    this.isPromptVisible = false;
  }
  deleteItem() {
    if (this.uri) {
      jsonAPI
        .delete(this.uri)
        .then(() => {
          this.$emit("deleted");
          this.loading = false;
          this.close();
        })
        .catch(() => {
          window.Bus.$emit("flash-message", {
            text: `Error deleting ${this.name}`,
            type: "error",
          });
          this.loading = false;
        });
    } else {
      this.$emit("confirm");
      this.close();
    }
  }
}
