<template>
  <component :is="layout">
    <prompt :active="isModalVisible" @close="isModalVisible = false">
      <h3 slot="header">
        Add new status
      </h3>
      <div slot="content">
        <status-form
          @close="isModalVisible = false"
          @add-status="addStatus"
          v-if="hasPermission('create-status')"
        />
      </div>
    </prompt>
    <prompt :active="isEditStatusVisible" @close="isEditStatusVisible = false">
      <h3 slot="header">
        Edit {{ selectedStatus.label }}
      </h3>
      <div slot="content">
        <status-edit-form
          @close="isEditStatusVisible = false"
          :status="selectedStatus"
          @update-status="updateStatus"
          v-if="hasPermission('create-status') && isEditStatusVisible"
        />
      </div>
    </prompt>
    <div class="row fadeInUp" role="main">
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-md-3">
            <ConfigurationNav />
          </div>
          <div class="col-12 col-md-9">
            <div class="card border-0 shadow-sm">
              <div class="card-header">
                <h4>
                  Status
                  <button
                    class="btn btn-sm float-right btn-outline-secondary m-1"
                    @click="showStatusModal"
                    v-if="hasPermission('create-status')"
                  >
                    Add status
                  </button>
                </h4>
              </div>
              <div>
                <div
                  v-if="Object.keys(statusList).length !== 0"
                  class="card-body"
                >
                  <data-table
                    :headers="headers()"
                    :data="statusList"
                    :sort-url="'/configuration/status'"
                    :method="'get'"
                  >
                    <template #name="{ item }">
                      {{ item.label }}
                    </template>
                    <template #slug="{ item }">
                      {{ item.key }}
                    </template>
                    <template #created_at="{ item }">
                      {{ item.created_at | formatLocaleDateString }}
                    </template>
                    <template #updated_at="{ item }">
                      {{ item.updated_at | formatLocaleDateString }}
                    </template>
                    <template #actions="{ item }">
                      <div>
                        <button
                          class="btn btn-sm btn-outline-secondary m-1"
                          @click="showEditModal(item)"
                        >
                          Edit
                        </button>
                        <DeleteButton
                          class="d-inline-block"
                          :uri="''"
                          :name="item.name"
                          :isSmall="true"
                          @confirm="deleteContent(item)"
                        />
                      </div>
                    </template>
                  </data-table>
                </div>
                <div v-else class="card-body">
                  <p class="text-muted">
                    This is a list of your statuses.
                  </p>
                  <div class="p-4 rounded border-zip text-center">
                    <p class="mb-0">
                      No statuses available!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import jsonAPI from "@/api";
import DataTable from "@/components/DataTable/DataTable.vue";
import Default from "@/layouts/default.vue";
import ConfigurationNav from "@/components/Nav/ConfigurationNav.vue";
import Prompt from "@/components/UI/Prompt.vue";
import StatusForm from "@/components/Form/StatusForm.vue";
import StatusEditForm from "@/components/Form/StatusEditForm.vue";
import DeleteButton from "@/components/Form/DeleteButton.vue";
import Permission from "@/utils/permission";

export default {
  name: "StatusIndex",
  extends: Permission,
  components: {
    DataTable,
    Default,
    ConfigurationNav,
    Prompt,
    StatusForm,
    StatusEditForm,
    DeleteButton,
  },
  data: function() {
    return {
      layout: "Default",
      loading: false,
      isModalVisible: false,
      status: {
        data: {},
      },
      statusList: [],
      statusId: "",
      isEditStatusVisible: false,
      selectedStatus: "",
    };
  },
  async mounted() {
    jsonAPI.get("/configuration/status").then((response) => {
      if (response.data && response.data.data.length > 0) {
        this.status = response.data.data[0];
        this.statusList = this.status.data.status;
        this.$store.dispatch("SET_STATUS_LIST", this.statusList);
      }
    });
  },
  methods: {
    headers() {
      return [
        { title: "Name", key: "label", sortable: true, sortKey: "label" },
        { title: "Slug", key: "key", sortable: false, sortKey: "key" },
        { title: "Actions", key: "actions" },
      ];
    },
    addStatus(status) {
      this.status.data.status.push(status);
      jsonAPI
        .put("configurationData/" + this.status.id, this.status)
        .then(() => {
          window.Bus.$emit("flash-message", {
            text: "Status has been added",
            type: "success",
          });
          this.statusList = this.updateStatusList();
          this.$store.dispatch("SET_STATUS_LIST", this.statusList);
        });
    },
    showEditModal(item) {
      this.isEditStatusVisible = true;
      this.selectedStatus = item;
    },
    updateStatus(status) {
      this.status.data.status = this.status.data.status.filter((obj) => {
        if(obj.key === status.key) {
          obj.label = status.label;
        }
        return obj;
      });
      jsonAPI
        .put("configurationData/" + this.status.id, this.status)
        .then(() => {
          window.Bus.$emit("flash-message", {
            text: "Status has been updated",
            type: "success",
          });
          this.statusList = this.updateStatusList();
          this.$store.dispatch("SET_STATUS_LIST", this.statusList);
        });
    },
    deleteContent(status) {
      delete this.status.data.status[status.key];
      this.status.data.status = this.status.data.status.filter((obj) => {
        return obj.key !== status.key;
      });
      this.statusList = this.updateStatusList();
      jsonAPI
        .put("configurationData/" + this.status.id, this.status)
        .then(() => {
          window.Bus.$emit("flash-message", {
            text: "Status has been deleted",
            type: "success",
          });
          this.statusList = this.status.data.status;
          this.$store.dispatch("SET_STATUS_LIST", this.statusList);
        });
    },
    updateStatusList() {
      const dataList = [];
      for (const key in this.status.data.status) {
        dataList.push(this.status.data.status[key]);
      }
      return dataList;
    },
    showStatusModal() {
      this.isModalVisible = true;
    },
  },
};
</script>
